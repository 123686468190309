@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import './assets/style/variables.module.scss';

* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  object-fit: cover;
  box-sizing: border-box;
}
#root {
  min-height: 100vh;
  max-width: 1140px;
  margin: auto;
}

main {
  width: 90%;
  margin: auto;
  @media (max-width: 769px) {
    width: 80%;
    margin: auto;
  }
}
