@import '../../assets/style/variables.module.scss';

article {
  width: 100%;

  .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
      display: block;
      border-radius: calc(0.1rem + 1vw);
    }

    p {
      position: absolute;
      bottom: 10%;
      left: 10%;
      color: $background-color;
      font-size: 18px;
      font-weight: 300;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    }
  }
}
