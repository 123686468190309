@import '../../assets/style/variables.module.scss';

.tag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 769px) {
    justify-content: start;
    align-items: center;
  }

  p {
    color: $background-color;
    background-color: $main-color;
    padding: 5px 30px;
    border-radius: 10px;
    @media (max-width: 769px) {
      padding: 5px 20px;
    }
  }
}
