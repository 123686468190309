footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: auto;
  padding-top: 64px;
  padding-bottom: 30px;
  background-color: #000;
  margin-top: 20px;
  img {
    height: 50px;
    object-fit: contain;
  }
  p {
    color: #fff;
    font-size: 24px;
    padding-top: 30px;
    text-align: center;
    @media (max-width: 769px) {
      font-size: 12px;
      width: 30%;
    }
  }
}
