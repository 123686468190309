@import '../../assets/style/variables.module.scss';

.rating {
  font-size: 50px;
  img {
    @media (max-width: 769px) {
      width: 25px;
    }
  }

  .star {
    padding-right: 5px;
  }
}
