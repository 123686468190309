@import '../../assets/style/variables.module.scss';

.collapse-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 769px) {
    flex-direction: column;
    gap: 5px;
  }
}

.apartment-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  @media (max-width: 769px) {
    padding: 20px 0 0 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .apartment-name-location {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .apartment-name {
      color: $main-color;
      font-size: 36px;
      font-weight: 400;
    }

    P {
      font-weight: 500;
    }

    .apartment-tag {
      display: flex;
    }
  }

  .apartment-owner-rating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;
    width: 100%;
    @media (max-width: 769px) {
      padding: 20px 0 0 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .apartment-owner {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      @media (max-width: 769px) {
        order: 2;
      }
    }

    .rating-mobile {
      @media (max-width: 769px) {
        order: 1;
        display: flex;
        align-items: center;
      }
    }

    p {
      text-align: end;
      line-height: 1.5;
      color: $main-color;
      white-space: pre-line;
    }

    .owner-image {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      @media (max-width: 769px) {
        width: 50px;
        height: 50px;
      }
    }
  }
}
