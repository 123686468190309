@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap);
.logements-cards{margin-top:70px;display:flex;flex-wrap:wrap;grid-gap:20px;gap:20px}@media(max-width: 769px){.logements-cards{margin-top:30px}}.logements-cards a{border-radius:10px;overflow:hidden;width:31%;height:100%;cursor:pointer;text-decoration:none}@media(max-width: 769px){.logements-cards a{width:100%}}
.banner{position:relative;width:100%;height:223px;border-radius:50px;overflow:hidden;background-color:#000}@media(max-width: 769px){.banner{height:100px;border-radius:20px}}.banner img{position:absolute;top:0;right:0;bottom:0;left:0;width:100%;height:100%;border-radius:inherit;opacity:.7}.banner .h1Show{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);text-align:center;color:#f6f6f6;z-index:1;font-size:40px;white-space:nowrap}@media(max-width: 769px){.banner .h1Show{left:45%;width:70%;font-size:24px;text-align:start;white-space:pre-wrap}}.banner .h1Hide{display:none}
article{width:100%}article .image-container{position:relative;width:100%;height:100%}article .image-container img{width:100%;height:350px;object-fit:cover;display:block;border-radius:calc(.1rem + 1vw)}article .image-container p{position:absolute;bottom:10%;left:10%;color:#f6f6f6;font-size:18px;font-weight:300;text-shadow:1px 1px 2px rgba(0,0,0,.5)}

.collapse{margin-top:50px;margin-bottom:30px;width:100%}@media(max-width: 769px){.collapse{margin-top:20px;margin-bottom:20px}}.collapse .collapse-header{width:100%}.collapse .collapse-header .collapse-title{display:flex;justify-content:space-between;padding:20px;width:100%;margin:auto;background-color:#ff6060;color:#f6f6f6;border-radius:20px}@media(max-width: 769px){.collapse .collapse-header .collapse-title{width:100%;padding:15px}}.collapse .collapse-header .collapse-title .arrow-reversed{transform:rotate(-0.5turn)}.collapse .collapse-content{display:flex;flex-direction:column;padding:20px;color:#000;font-size:18px;background-color:#f6f6f6;border-radius:20px}
header{display:flex;flex-direction:row;justify-content:space-between;align-items:center;padding:40px 100px 20px 100px}@media(max-width: 769px){header{padding:20px}}header img{height:68px;object-fit:contain}@media(max-width: 769px){header img{height:47px}}header nav a{margin-right:20px;text-decoration:none;color:#000}header nav a:last-child{margin-right:0}header nav a.active{-webkit-text-decoration:underline #000;text-decoration:underline #000}@media(max-width: 769px){header nav{text-transform:uppercase}}
footer{display:flex;flex-direction:column;align-items:center;width:100%;margin-top:auto;padding-top:64px;padding-bottom:30px;background-color:#000;margin-top:20px}footer img{height:50px;object-fit:contain}footer p{color:#fff;font-size:24px;padding-top:30px;text-align:center}@media(max-width: 769px){footer p{font-size:12px;width:30%}}
.error-404,.error-text{text-align:center;color:#ff6060}.error-404{font-size:288px;font-weight:700;margin-top:100px}@media(max-width: 768px){.error-404{font-size:26vw}}.error-text{margin-top:20px;font-size:36px}@media(max-width: 768px){.error-text{font-size:24px;padding:10px}}@media(max-width: 480px){.error-text{font-size:18px}}.error-link{color:#000;text-align:center;margin-top:130px;margin-bottom:100px}@media(max-width: 768px){.error-link{font-size:14px}}
.collapse-content{display:flex;width:100%;justify-content:space-between;grid-gap:20px;gap:20px}@media(max-width: 769px){.collapse-content{flex-direction:column;grid-gap:5px;gap:5px}}.apartment-info{display:flex;justify-content:space-between;align-items:center;padding:30px 0}@media(max-width: 769px){.apartment-info{padding:20px 0 0 0;flex-direction:column;align-items:flex-start}}.apartment-info .apartment-name-location{display:flex;flex-direction:column;grid-gap:20px;gap:20px;width:100%}.apartment-info .apartment-name-location .apartment-name{color:#ff6060;font-size:36px;font-weight:400}.apartment-info .apartment-name-location P{font-weight:500}.apartment-info .apartment-name-location .apartment-tag{display:flex}.apartment-info .apartment-owner-rating{display:flex;flex-direction:column;justify-content:center;align-items:flex-end;grid-gap:20px;gap:20px;width:100%}@media(max-width: 769px){.apartment-info .apartment-owner-rating{padding:20px 0 0 0;flex-direction:row;align-items:center;justify-content:space-between}}.apartment-info .apartment-owner-rating .apartment-owner{display:flex;justify-content:center;align-items:center;grid-gap:20px;gap:20px}@media(max-width: 769px){.apartment-info .apartment-owner-rating .apartment-owner{order:2}}@media(max-width: 769px){.apartment-info .apartment-owner-rating .rating-mobile{order:1;display:flex;align-items:center}}.apartment-info .apartment-owner-rating p{text-align:end;line-height:1.5;color:#ff6060;white-space:pre-line}.apartment-info .apartment-owner-rating .owner-image{border-radius:50%;width:100px;height:100px}@media(max-width: 769px){.apartment-info .apartment-owner-rating .owner-image{width:50px;height:50px}}
.gallery{position:relative}.gallery .carousel{position:relative;display:flex;align-items:center}.gallery .carousel .arrow{position:absolute;top:50%;transform:translateY(-50%);background:none;border:none;cursor:pointer}@media(max-width: 769px){.gallery .carousel .arrow img{height:40px}}.gallery .carousel .left{left:10px}.gallery .carousel .right{right:10px}.gallery .carousel .picture-carrousel{border-radius:50px;width:100%;height:415px}@media(max-width: 769px){.gallery .carousel .picture-carrousel{border-radius:20px}}.gallery .carousel .count-pictures{position:absolute;bottom:10px;left:50%;transform:translateX(-50%);color:#f6f6f6;z-index:1;font-size:20px;font-weight:600}@media(max-width: 769px){.gallery .carousel .count-pictures{display:none}}
.rating{font-size:50px}@media(max-width: 769px){.rating img{width:25px}}.rating .star{padding-right:5px}
.tag{display:flex;justify-content:flex-start;align-items:center;flex-wrap:wrap;grid-gap:20px;gap:20px}@media(max-width: 769px){.tag{justify-content:start;align-items:center}}.tag p{color:#f6f6f6;background-color:#ff6060;padding:5px 30px;border-radius:10px}@media(max-width: 769px){.tag p{padding:5px 20px}}
*{font-family:"Montserrat",sans-serif;margin:0;padding:0;object-fit:cover;box-sizing:border-box}#root{min-height:100vh;max-width:1140px;margin:auto}main{width:90%;margin:auto}@media(max-width: 769px){main{width:80%;margin:auto}}
