@import '../../assets/style/variables.module.scss';

.gallery {
  position: relative;
  .carousel {
    position: relative;
    display: flex;
    align-items: center;

    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      cursor: pointer;
      img {
        @media (max-width: 769px) {
          height: 40px;
        }
      }
    }

    .left {
      left: 10px;
    }

    .right {
      right: 10px;
    }

    .picture-carrousel {
      border-radius: 50px;
      width: 100%;
      height: 415px;
      @media (max-width: 769px) {
        border-radius: 20px;
      }
    }

    .count-pictures {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      color: $background-color;
      z-index: 1;
      font-size: 20px;
      font-weight: 600;
      @media (max-width: 769px) {
        display: none;
      }
    }
  }
}
