@import '../../assets/style/variables.module.scss';

.banner {
  position: relative;
  width: 100%;
  height: 223px;
  border-radius: 50px;
  overflow: hidden;
  background-color: black;
  @media (max-width: 769px) {
    height: 100px;
    border-radius: 20px;
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    opacity: 0.7;
  }

  .h1Show {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $background-color;
    z-index: 1;
    font-size: 40px;
    white-space: nowrap;
    @media (max-width: 769px) {
      left: 45%;
      width: 70%;
      font-size: 24px;
      text-align: start;
      white-space: pre-wrap;
    }
  }

  .h1Hide {
    display: none;
  }
}
